/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/bg.png";//"assets/images/bg-presentation.jpg";
import uiImage from "assets/ui_2.png";//"assets/images/bg-presentation.jpg";
import einkImage from "assets/smallscreens.png";//"assets/images/bg-presentation.jpg";
import diagramImage from "assets/diagram.png"
import logoImage from "assets/TTRPG_logo.png"
import ExampleCard from "./components/ExampleCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import { Height } from "@mui/icons-material";

function Presentation() {
  return (
    <>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
        <Grid container item xs={12} lg={7} justifyContent="center" mx="auto" backgroundColor="white">
          <img src={logoImage} width="65%"></img>
        </Grid>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto" backgroundColor="white">
            <MKTypography
              variant="h2"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["10xl"],
                },
              })}
            >
              TTRPGGadgets{" "}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <MKBox p={2} textAlign="center" lineHeight={1}>
          <h2> Welcome to TTRPGGadgets,</h2> 
          <br></br>
          <h3>We aim to build software and hardware for techy gamemasters, ideally having a screen that displays an image of an envirnoment</h3> 
          <h3>Along with each player having an eink display showing their character stats and allowing the gamemaster to update that sheet</h3>
        </MKBox>
        <Information />
        <MKBox p={2} textAlign="center" lineHeight={1}>
          <h2>Products</h2>
          <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  title="User Interface"
                  description="Simple and easy way to use AI text to image, image to image and LLM. All intergrated with OBS to controll a screen to show your players. All designed to provide gamemasters a quick and easy way to design on the fly as players wonder off course into unprepared areas of the world."
                />
                <img src={uiImage} width="100%"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  title="Eink tablets"
                  description="Provide a way for player of all exsperience levels to just drop into a game of their choice. Do away with hours of guiding a player though character creation with easy homebrew intergration"
                />
                <img src={einkImage} width="100%"/>
              </Grid>
            </Grid>
        </MKBox>
        <br/>
        <img src={diagramImage} width="100%"/>
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your interest!
                </MKTypography>
                <MKTypography variant="b1" fontWeight="bold" mb={0.5}>
                  Please email: ttrpggadgets@gmail.com
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://x.com/TTRPGGadgets"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.instagram.com/ttrpggadgets/"
                  target="_blank"
                  color="instagram"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-instagram" />
                  &nbsp;Share
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default Presentation;
